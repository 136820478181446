import React, { useState, useEffect, useContext } from 'react';

import lancely from '../../utils/lancely';
import { UserContext } from '../../context/UserContext';

import { Container, Row, Col, Card, Table } from 'react-bootstrap';

import './Projects.css';

function Projects({ navigation }) {

    const { state } = useContext(UserContext);
    const [ projects, setProjects ] = useState([]);
    const [ page, setPage ] = useState(0)

    useEffect(() => {
        lancely.projects.getAll(page)
            .then(resp => {
                console.log(resp);
                setProjects(resp.projects);
            })
    }, [])

    if (state && !state.user) {
        return <div>Loading...</div>
    }

    return (
        <>
            <Container className={`mt-5`}>
                <Row>
                    <Col md={12}>
                        <p className={`display-5 fw-light`}>Projects</p>
                        <p className={`lead`}>{projects.length} Projects</p>
                        <Card>
                            <Card.Body style={{ padding: 0 }}>
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" />
                                                </div>
                                            </th>
                                            <th>Project Title</th>
                                            <th>Company / Person</th>
                                            <th>Role</th>
                                            <th>Forecast</th>
                                            <th>Recent Activity</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {projects.map((project, index) => {
                                            // if ()
                                            return (
                                                <tr key={index}>
                                                    <th>
                                                        <div className="form-check">
                                                            <input id={`project-checkbox-${index}`} className="form-check-input" type="checkbox" value="" />
                                                        </div>
                                                    </th>
                                                    <td>{project.name}</td>
                                                    <td>{project.name}</td>
                                                    <td>{project.name}</td>
                                                    <td>{project.name}</td>
                                                    <td>{project.name}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Projects;
