import React, { useEffect, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import lancely from './utils/lancely';
import { SET_TOKENS, CLEAR, UserContext } from "./context/UserContext";

import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
// import ProtectedRoute from "./utils/ProtectedRoute";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';

import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "./components/Sidebar/Sidebar";
import GlobalSearch from "./components/Sidebar/GlobalSearch";
import NotFound from "./components/NotFound";

import io from 'socket.io-client';
import Projects from "./components/Projects/Projects";

export default function App() {

  const { dispatch, state } = useContext(UserContext);
  const [ socket, setSocket ] = useState(null);

  useEffect(() => {
    if (state && state.tokens !== null) {
      // State is loaded!
      let sock = io(process.env.REACT_APP_API_URL, {
        query: {
          token: state.tokens.access_token
        }
      })
      setSocket(sock)
      sock.on('notification', (data) => {
        console.log(`New Notification:`, data);
        toast.success(data);
      })
      sock.on("connect_error", (err) => {
        toast.error(err.message);
        // if (err.message === "invalid username") {
          // this.usernameAlreadySelected = false;
        // }
      });
    }
  }, [state])

  useEffect(() => {
    const interval = setInterval(() => {
      // Refresh the tokens:
      if (state && state.tokens !== null) {
        lancely.users.refresh()
          .then(resp => {
            dispatch({
              type: SET_TOKENS,
              payload: { ...resp.data.tokens }
            })
          })
      }
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const routes = [
    { component: <Home />, path: '/' },
    { component: <Projects />, path: '/projects'}
  ];

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Container fluid className={`g-0`} style={{ backgroundColor: "#F5F6F8" }}>
        <Row className={`g-0`}>
          <Router>
            <Col
              md={'auto'}
              style={{
                maxWidth: '300px',
                width: '30vw',
                // zIndex: 1
              }}
              id="sidebar-wrapper"
              className={state.user !== null ? '' : 'd-none'}
            >
              <Sidebar />
            </Col>
            <Col id="page-content-wrapper" style={{ maxHeight: "100vh", overflowY: "auto" }}>
              {state.user !== null && <GlobalSearch socket={socket} />}
              <Routes>
                {state.user !== null &&
                  routes.map((route, index) => {
                    return <Route key={index} path={route.path} element={route.component} />
                  })
                }
                <Route path={state.user === null ? "/" : "/login"} element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="*" element={<NotFound />}></Route>
              </Routes>
            </Col>
          </Router>
        </Row>
      </Container>
    </>
  );
}
