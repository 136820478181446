import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';

import lancely from '../../utils/lancely';
import { UserContext } from '../../context/UserContext';

import { Container, Row, Col, Card, ProgressBar, Image } from 'react-bootstrap';

import { format, addDays, subDays, getDay } from 'date-fns'

import './Home.css'


const Home = ({ navigation }) => {

    const navigate = useNavigate();
    const [weekStart, setWeekStart] = useState(new Date());
    const [selectedDay, setSelectedDay] = useState(new Date());
    const { state } = useContext(UserContext);
    const [tasks, setTasks] = useState([
        {
            title: <>Submit final edited photos to <a href="#">@Coca Cola</a></>,
            due: new Date(),
            type: "Reminder",
            user: {
                firstName: "George",
                lastName: "Fields",
                profilePicture: "https://randomuser.me/api/portraits/men/67.jpg"
            },
            completed: true
        },
        {
            title: <>Call <a href="#">@Rebecca Moore</a> from <a href="#">@Apple</a></>,
            due: addDays(new Date(), 1),
            type: "Call",
            user: {
                firstName: "Rebecca",
                lastName: "Moore",
                profilePicture: "https://randomuser.me/api/portraits/women/67.jpg"
            },
            completed: false
        },
        {
            title: <>Private Photoshoot with <a href="#">@Diana Greer</a></>,
            due: addDays(new Date(), 2),
            type: "Event",
            user: {
                firstName: "Diane",
                lastName: "Greer",
                profilePicture: "https://randomuser.me/api/portraits/women/45.jpg"
            },
            completed: true
        }
    ]);

    useEffect(() => {
        let temp = subDays(new Date(), getDay(new Date()));
        setWeekStart(temp);
    }, [])

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const shuffle = () => {
        setTasks(shuffleArray(tasks));
    }

    const getGreeting = () => {
        let curHr = new Date().getHours();
        if (curHr < 12) {
            return 'Good morning,'
        } else if (curHr < 18) {
            return 'Good afternoon,'
        } else {
            return 'Good evening,'
        }
    }

    if (state && !state.user) {
        return <p>Loading...</p>
    }

    return (
        <>
            <Container className={`mt-5`}>
                <Row>
                    <Col md={12}>
                        <p className="display-5 fw-light">{getGreeting()} {state.user.firstName}</p>
                    </Col>
                    <Col md={8}>
                        <Card>
                            <Card.Body>
                                <p className={`tasks-and-dropdown`}>
                                    <span className={`lead fw-bold`}>
                                        {tasks.filter(t => t.completed).length} tasks completed out of {tasks.length}
                                    </span>
                                    <span className={`show-dropdown text-muted`}>
                                        Show: &nbsp;
                                        <select className={`dropdown-select-week`}>
                                            <option>Last Week</option>
                                            <option selected>This Week</option>
                                            <option>Next Week</option>
                                        </select>
                                    </span>
                                </p>
                                <ProgressBar variant="success" now={(tasks.filter(t => t.completed).length / tasks.length) * 100} />
                                <p className={`mt-5 fs-3 fw-bold`}>
                                    {format(new Date(), 'cccc, LLLL do, yyyy')}
                                </p>
                                <div>
                                    <p className={`d-flex flex-row justify-content-evenly text-center fs-5`}>
                                        {
                                            [0, 1, 2, 3, 4, 5, 6].map((index) => {
                                                return (
                                                    <div className={`d-flex flex-column ${getDay(new Date()) === index ? 'text-primary' : 'text-muted'}`}>
                                                        <span>{format(addDays(weekStart, index), 'E')}</span>
                                                        <button
                                                            className={`btn 
                                                                            ${getDay(selectedDay) === index ? 'btn-primary' : 'btn-default'} 
                                                                            ${getDay(new Date()) === index && getDay(selectedDay) !== index ? 'text-primary' : ''}
                                                                            btn-md btn-circle fs-2 d-flex flex-row justify-content-center`}
                                                            onClick={() => {
                                                                setSelectedDay(addDays(weekStart, index))
                                                                shuffle();
                                                            }}
                                                        >
                                                            {format(addDays(weekStart, index), 'd')}
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </p>
                                </div>
                                <hr />
                                {
                                    tasks.map((task, index) => {
                                        return (
                                            <Card className={`card-hover mb-5`}>
                                                <Card.Body>
                                                    <p className={`d-flex justify-content-between`}>
                                                        <span className={`fs-3 fw-bold`}>{task.title}</span>
                                                        <span className={`fs-4 text-muted`}>{task.type}</span>
                                                    </p>
                                                    <p className={`fw-bold text-muted`}>Due Date: {format(task.due, 'LLLL do, yyyy')}</p>
                                                    <div className={`d-flex justify-content-between align-items-center`}>
                                                        <p className={`lead text-muted fw-bold`}>
                                                            <Image
                                                                roundedCircle
                                                                fluid
                                                                src={task.user.profilePicture || "/assets/icons/user.png"}
                                                                style={{
                                                                    backgroundColor: "#f0f0f0",
                                                                    width: "32px",
                                                                    height: "32px"
                                                                }}
                                                            />
                                                            &nbsp;
                                                            {task.user.firstName} {task.user.lastName}
                                                        </p>
                                                        <div className={`text-white p-3 d-flex align-items-center rounded-pill ${task.completed ? 'bg-success' : 'bg-danger'}`}>
                                                            {task.completed ? "Completed" : "To Do"}
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        );
                                    })
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Body>
                                Hello World
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Home
