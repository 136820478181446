import React, { useContext } from 'react'

import { BiSearchAlt2 } from 'react-icons/bi';
import { MdNotificationsNone, MdOutlineLogout } from 'react-icons/md';

import { CLEAR, UserContext } from '../../context/UserContext';

import { NavDropdown } from 'react-bootstrap';

function GlobalSearch({ socket }) {

    const { dispatch } = useContext(UserContext);

    return (
        <nav className="navbar navbar-expand-xl navbar-light bg-white global-search pt-3 pb-4" style={{ borderBottom: "1px solid #cccccc" }}>
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <form className="d-flex align-items-center ms-10 global-search-input">
                        <BiSearchAlt2 size={"2.5rem"} />
                        <input className="form-control me-2" type="search" placeholder="Global Search" aria-label="Global Search" />
                    </form>
                    <ul className="navbar-nav ms-auto mb-2 me-5 mb-lg-0">
                        <NavDropdown title={<MdNotificationsNone size={"2rem"} />} id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href="#"
                                onClick={() => {
                                    dispatch({ type: CLEAR });
                                    socket.emit('logout', true);
                                }}
                            >
                                <MdOutlineLogout size={"2rem"} />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default GlobalSearch
